@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://use.fontawesome.com/releases/v5.2.0/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Lilita+One&family=Rowdies:wght@300&display=swap);

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-weight: lighter;
}

html {
  overflow: hidden;
}

strong {
  /* override reboot.css */
  font-weight: 700 !important; 
}
